import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  ROLE,
} from "baseui/modal";
import { LabelLarge, ParagraphMedium } from "baseui/typography";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { TextButton } from "../TextButton";
import _ from "lodash";
import { toast } from "react-toastify";

const ConfirmationModel = ({
  modelName,
  PromisePopUp,
  setIsOpen,
  isOpen,
  isConfirmMessage,
  setIsConfirmMessage,
  setEnablePayment,
  ...rest
}) => {
  console.log(isConfirmMessage, "setIsConfirmMessage");
  function close() {
    setIsOpen(false);
  }
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  let { selectedInvoice } = useSelector((s) => s.invoiceReducer);
  let layoutData = useSelector((s) => s.layoutReduceData);

  return (
    <Modal
      overrides={{
        Root: {
          style: ({ $theme }) => ({
            textAlign: "center",
          }),
        },
        Close: {
          style: ({ $theme }) => ({
            display: "none",
          }),
        },
        Dialog: {
          style: ({ $theme }) => ({
            padding: "20px",
            width: "450px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            boxShadow: "0px 4px 4px 0px #33386050 ",
          }),
        },
      }}
      onClose={close}
      isOpen={isOpen}
      animate
      role={ROLE.dialog}
    >
      <LabelLarge>Confirmation Required</LabelLarge>
      {isConfirmMessage &&
        Array.isArray(isConfirmMessage.layoutData) &&
        isConfirmMessage.layoutData.map((item, index, array) => {
          return (
            <div>
              <ModalBody>
                <ParagraphMedium
                  $style={{ whiteSpace: "pre-wrap", textAlign: "left" }}
                >
                  {item}
                </ParagraphMedium>
              </ModalBody>
              {index !== array.length - 1 ? (
                <div
                  style={{
                    border: "1px solid #EEEEEE",
                    textAlign: "center",
                  }}
                ></div>
              ) : (
                <></>
              )}
            </div>
          );
        })}

      <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        <TextButton
          kind="tertiary"
          onClick={close}
          style={{ textAlign: "center" }}
          size="mini"
        >
          Cancel
        </TextButton>
        <TextButton
          onClick={async () => {
            if (isButtonDisabled) return;

            setIsButtonDisabled(true); // Disable the button after the first click

            try {
              if (modelName === "PAYMENT") {
                setEnablePayment(true);
                rest.setPaymentId(isConfirmMessage?.paymentData);
                rest.setOfflineData(
                  _.get(
                    isConfirmMessage,
                    "paymentData.offline_payment_info",
                    ""
                  )
                );

                if (
                  _.get(isConfirmMessage, "paymentData.payment_mode", "") ===
                  "offline"
                ) {
                  rest.setIsOutcome("ACC_DTL");
                  setIsOpen(false);
                } else {
                  rest.setIsOutcome("PAY_VIA");
                }

                let selectedInvoiceID = selectedInvoice.map((i) => i._id);
                if (
                  _.get(isConfirmMessage, "paymentData.payment_mode", "") ===
                  "CLP"
                    ? _.get(layoutData, "customer.min_payment", 0) <=
                      _.get(isConfirmMessage, "paymentData.isText", 0)
                    : false
                ) {
                  window.location.href = _.get(
                    rest,
                    "redirect_url",
                    _.get(isConfirmMessage, "paymentData.redirect_url", "")
                  );
                  return null;
                }
                let values = {
                  payment_id: _.get(isConfirmMessage, "paymentData._id", ""),
                  allocated_to: selectedInvoiceID,
                  ...(_.get(isConfirmMessage, "paymentData.payment_type", "")
                    .length > 0
                    ? {
                        payment_type: _.get(
                          isConfirmMessage,
                          "paymentData.payment_type",
                          ""
                        ),
                      }
                    : {}),
                  confirmation_message: _.get(
                    isConfirmMessage,
                    "layoutData",
                    []
                  ).join(","),
                  currency:
                    selectedInvoice &&
                    selectedInvoice[0] &&
                    selectedInvoice[0].invoiceCurrency,
                  amount: Number(
                    _.get(isConfirmMessage, "paymentData.isText", "")
                  ),
                };

                if (
                  _.get(isConfirmMessage, "paymentData.payment_mode") ===
                    "online" &&
                  rest.postOnline &&
                  !rest.postOnline.isLoading
                ) {
                  if (
                    _.get(layoutData, "customer.min_payment", 0) <=
                    _.get(isConfirmMessage, "paymentData.isText", null)
                  ) {
                    await rest.postOnline.mutateAsync(values);
                  } else if (
                    Array.isArray(selectedInvoice) &&
                    selectedInvoice.length === 1 &&
                    selectedInvoice[0].invoiceTotalAmount <=
                      _.get(layoutData, "customer.min_payment", 0)
                  ) {
                    await rest.postOnline.mutateAsync({
                      ...values,
                      amount: selectedInvoice[0].invoiceTotalAmount,
                    });
                  } else {
                    toast.error("Payment Amount Exceeds Invoice Due Amount");
                  }
                }
              } else if (modelName === "SINGLE_PAYMENT") {
                let values = {
                  confirmation_message: _.get(
                    isConfirmMessage,
                    "layoutData"
                  ).join(","),
                  ..._.get(isConfirmMessage, "promiseData"),
                };
                console.log(values, "Values____");
                await rest.requestTime.mutateAsync(values);
              } else if (modelName === "PAYMENT_PLAN") {
                let values = {
                  confirmation_message: _.get(
                    isConfirmMessage,
                    "layoutData"
                  ).join(","),
                  ..._.get(isConfirmMessage, "paymentPlanData"),
                };
                console.log(values, "Values____");
                await rest.paymentPlanneds.mutateAsync(values);
              } else if (modelName === "PAYMENT_PLAN_DATE") {
                let values = {
                  confirmation_message: _.get(
                    isConfirmMessage,
                    "layoutData"
                  ).join(","),
                  ..._.get(isConfirmMessage, "paymentPlanData"),
                };

                await rest.postTransfer.mutateAsync(values);
              }
            } catch (error) {
              console.error("Payment error:", error);
            } finally {
              setIsButtonDisabled(false);
            }
          }}
          disabled={isButtonDisabled}
          size={"mini"}
        >
          Confirm & Proceed
        </TextButton>
      </div>
    </Modal>
  );
};

export default ConfirmationModel;
