import React, { useState, useContext, useEffect, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useJwt, isExpired, decodeToken } from "react-jwt";
import axios from "axios";
import { getToken, refreshAccessToken } from "../services/auth";
import PublickRouters from "../router/combineRouters/PublickRouters";
import { IntlProvider } from "react-intl";

const axiosWithAuth = axios.create();
/*
  DO NOT STORE AUTHENTICATION OR AUTHORIZATION RELATED INFORMATION IN STORAGE APIS (LOCALSTORAGE) (https://github.com/OWASP/CheatSheetSeries/blob/master/cheatsheets/HTML5_Security_Cheat_Sheet.md#local-storage)
*/

const AuthContext = React.createContext({
  token: null,
  refreshToken: null,
  idToken: null,
  rootOrganization: null,
  onReady: () => {},
  onAuthSucess: () => {},
  onAuthError: () => {},
  onAuthRefreshSuccess: () => {},
  onAuthRefreshError: () => {},
  onAuthLogout: () => {},
  onTokenExpired: () => {},
  // init: () => {}, //Promise
  updateToken: () => {}, //Promise minValidity
});

// const useQuery = () => new URLSearchParams(useLocation().search);

let Location = () => {
  return useLocation();
};

const useAuth = () => {
  const state = useContext(AuthContext);

  return { ...state };
};

const AuthProvider = ({ children }) => {
  let { pathname, search } = Location();
  const navigate = useNavigate();

  const web = process.env.REACT_APP_WEB_LINK;

  // let hashCode = pathname.slice(1);

  // if (pathname === "/") {
  //   window.location.href = web;
  // }

  let [emplty, hash] = pathname.split("/");

  let authState = useAuth();

  if (hash.length > 8) {
    let validHashCode = hash.slice(0, 8);
    window.location.replace(`/${validHashCode}${search}`);
  }

  const [state, setState] = useState({
    accessToken: null,
    authorizeDocToken: null,
    refreshToken: null,
    isAuthenticated: false,
    isNavigated: false,
    hashCode: hash,
    // rootOrganization: null,
    isRefreshTokenExpired: true,
    onTokenExpired: () => {},
  });

  async function onTokenExpired(refreshToken) {
    try {
      let { data, status } = await refreshAccessToken(refreshToken);
      return {
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      };
    } catch (err) {}
  }

  useEffect(async () => {
    let persistedhashCode = localStorage.getItem("hC");
    let persistedRefreshToken = localStorage.getItem("rT");
    let isRefreshTokenExpired = true;
    if (persistedRefreshToken && persistedhashCode == hash) {
      //Check if refresh token is expired
      isRefreshTokenExpired = isExpired(localStorage.getItem("rT"));
    }

    if (persistedRefreshToken && !isRefreshTokenExpired) {
      const { refreshToken, accessToken } = await onTokenExpired(
        persistedRefreshToken
      );
      setState({
        ...state,
        accessToken,
        refreshToken,
        isAuthenticated: true,
      });
    } else if (!state.isAuthenticated && hash) {
      const getAuthCode = async () => {
        try {
          let { data, status, ...rest } = await getToken({ hash });
          if (status === 200 && data.accessToken) {
            setState({
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
              isAuthenticated: true,
            });
          }
        } catch (err) {}
      };

      getAuthCode();
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("rT", state.refreshToken);
    localStorage.setItem("hC", hash);
  }, [state]);

  const { decodedToken } = useJwt(state.accessToken);

  if (state.isAuthenticated) {
    axiosWithAuth.interceptors.request.use(
      (config) => {
        // Do something before request is sent
        config.headers.authorization = "Bearer " + state.accessToken;
        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error);
      }
    );
  }
  const defaultLocale = "en";
  let locale =
    navigator.language && navigator?.language?.split("-")[0]
      ? navigator?.language?.split("-")[0]
      : defaultLocale;
  return (
    <AuthContext.Provider value={{ ...state }}>
      {/* {children} */}
      {state.isAuthenticated ? children : <PublickRouters />}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext, useAuth, axiosWithAuth };
