import { KIND, SIZE } from "baseui/button";
import { LabelLarge, ParagraphLarge } from "baseui/typography";
import React, { useEffect, useState } from "react";
import TextButton from "../TextButton/TextButton";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { paymentVerify } from "../../services/customerPortal";
import PaymentOnlineVerify from "../../assets/img/svg/PaymentOnlineVerify";

const PaymentContext = ({
  type,
  title,
  img,
  subText,
  content,
  btnKind,
  btnOnclick,
  isGoBackBtn = true,
  Button,
}) => {
  const [count, setCount] = useState(1);
  const { hash, paymentId } = useParams();
  let navigator = useNavigate();

  const { data, isLoading } = useQuery(
    [`PAYMENT_STATUS_CHECK-${paymentId}-${count}`, { count }],
    async ({ queryKey }) => {
      let { count } = queryKey[1];
      if (type === "PAYMENT_STATUS_CHECK") {
        return await paymentVerify({ paymentId });
      }
    }
  );

  useEffect(() => {
    if (data && data.data && data.data.doc && data.data.doc.payment_status) {
      if (data.data.doc.payment_status === "success") {
        navigator(`/${hash && hash}/payment-success`);
      } else if (data.data.doc.payment_status === "failed") {
        navigator(`/${hash && hash}/payment-failed`);
      } else if (count >= 3) {
        navigator(`/${hash && hash}/payment-status-unknown`);
      } else {
        setTimeout(() => {
          setCount(count + 1);
        }, 5000);
      }
    }
  }, [data, isLoading]);


  return (
    <div className="invoices-container --payment_status">
      <div className="invoices-header-container --payment__modifer ">
        <div className="payment_list_container">
          <LabelLarge>{title}</LabelLarge>
          <div className="payment__processing">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "70%" }}>
                {img ? (
                  <>
                    <img src={img} />
                  </>
                ) : (
                  <>
                    <PaymentOnlineVerify />
                  </>
                )}
              </div>
            </div>

            <ParagraphLarge $style={{ textAlign: "center", color: "#333860" }}>
              {subText}
            </ParagraphLarge>
            <ParagraphLarge $style={{ textAlign: "center", color: "#787878" }}>
              {content}
            </ParagraphLarge>
          </div>
          {Button ? (
            <div style={{ width: "250px", paddingTop: "10px" }}>
              <Button />
            </div>
          ) : (
            <></>
          )}

          {isGoBackBtn ? (
            <div style={{ width: "250px", paddingTop: "10px" }}>
              <TextButton
                size={SIZE.compact}
                fullWidth
                type="button"
                kind={btnKind}
                onClick={() => {
                  navigator(`/${hash && hash}`);
                }}
                startEnhancer={
                  <i className="mx-icon-Group-16179 font-icon-color" />
                }
              >
                Go Back
              </TextButton>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentContext;
