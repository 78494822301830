import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeView from "../../views/Home/HomeView";

const PublickRouters = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeView />} />
    </Routes>
  );
};

export default PublickRouters;
