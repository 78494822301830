import { Avatar } from "baseui/avatar";
import {
  LabelLarge,
  LabelMedium,
  LabelSmall,
  ParagraphMedium,
} from "baseui/typography";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
// import DateSelect from "../../components/DatePicker/DatePicker";
import { IconButton, KIND, SIZE } from "../../components/IconButton";
import { SelectBox, SIZE as size } from "../../components/SelectBox/SelectBox";
import DateRangeSelect from "../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { TextBox } from "../../components/TextBox";
import { NumericFormat, useNumericFormat } from "react-number-format";
import { TextButton } from "../../components/TextButton";
import {
  paymentPlanned,
  createAutoDebitPaymentPlan,
  paymentPlanComputeFn,
} from "../../services/customerPortal";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { refetachLayoutData } from "../../redux/Layout/action";
import { async } from "q";
import Info from "../../assets/img/svg/Info";
import DownArrow from "../../assets/img/svg/DownArrow";
import _, { truncate } from "lodash";
import { currencyList } from "../../utils/Currency/CurrencyToSymbol";
import ConfirmationModel from "../../components/ConfirmationModel/ConfirmationModel";
import { LoanBroker } from "../../constants/IndustrialSector";
import Offer from "../../assets/img/svg/Offer";
import EditSvg from "../../assets/img/svg/EditSvg";
import TipSvg from "../../assets/img/svg/TipSvg";

const freqMoment = {
  WEEKLY: {
    type: "day",
    count: 7,
  },
  FORTNIGHTLY: {
    type: "day",
    count: 14,
  },
  MONTHLY: {
    type: "month",
    count: 1,
  },
  QUARTERLY: {
    type: "month",
    count: 3,
  },
};

const PlannedAmount = ({
  setIsSelect,
  items,
  isSelect,
  setCount,
  count,
  is_allow_auto_debit_plan = false,
  auto_debit_payment_gateways = [],
}) => {
  console.log("itemsitemsitemsitemsitems", items);
  // Header start
  const location = useLocation();
  let layoutData = useSelector((s) => s.layoutReduceData);
  const hash = location?.pathname?.split("/")[1];
  const { organization, customer, referenceData } = useSelector(
    (s) => s.layoutReduceData
  );
  let timeZone = useSelector(
    (e) => e.layoutReduceData?.organization?.time_zone
  );
  const intl = useIntl();
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {},
  });
  let { instalation_count } = watch();
  // let instalation_amount = watch("planned_installation_amount");
  let start_date = watch("planned_start_date");
  let frequency = watch("planned_frequency");
  let installationCount = watch("instalation_count");

  const [installments, setInstallments] = useState([]);
  const [isComputeinstallments, setIsComputeInstallments] = useState([]);
  const [isComputedData, setIsComputedData] = useState({});
  const [isPaymentPlanList, setIsPaymentPlanList] = useState(false);
  const [isCardLoader, setIsCardLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmMessage, setIsConfirmMessage] = useState({});

  console.log("installments111", installments, isComputeinstallments);

  // PP Loop Start
  let { planned_frequency, planned_installation_amount } = watch();
  const [userChangedAmount, setUserChangedAmount] = useState(false);
  // custom payment plan calucation

  const [isMinimum, setIsMinimum] = useState("");
  const [isDayDiff, setIsDayDiff] = useState("");

  console.log("isDayDiff", isDayDiff);

  const frequencyDays = {
    WEEKLY: 7,
    FORTNIGHTLY: 14,
    MONTHLY: 30,
    QUARTERLY: 90,
  };

  const instalmentExt = {
    ONE_WEEK: 7,
    TWO_WEEKS: 14,
    THREE_WEEKS: 21,
    ONE_MONTH: 30,
    TWO_MONTHS: 60,
    THREE_MONTHS: 90,
    SIX_MONTHS: 180,
    NINE_MONTHS: 270,
    ONE_YEAR: 365,
    TWO_YEAR: 730,
    THREE_YEAR: 1095,
    FOUR_YEAR: 1460,
    FIVE_YEAR: 1825,
    SIX_YEAR: 2190,
  };

  let filteredFrequency =
    referenceData && referenceData?.payment_plan_frequency
      ? referenceData?.payment_plan_frequency?.filter(
          (e) =>
            items &&
            items.manual_plan_setting &&
            items?.manual_plan_setting?.payment_plan_freequency?.includes(e.id)
        )
      : [];

  useEffect(() => {
    if (
      items &&
      items.manual_plan_setting &&
      items.manual_plan_setting.payment_plan_freequency &&
      filteredFrequency
    ) {
      setValue("planned_frequency", [{ id: filteredFrequency?.[0]?.id }]);
    }
  }, []);

  useEffect(() => {
    if (frequencyDays) {
      let findFrq =
        instalmentExt[
          items?.manual_plan_setting?.maximum_instalment_extention
        ] /
        frequencyDays[
          planned_frequency &&
            planned_frequency[0] &&
            planned_frequency[0].id &&
            planned_frequency[0].id
        ];
      let frequencyInNumber = Math.ceil(findFrq);

      // if (is_allow_auto_debit_plan) {
      setValue("instalation_count", frequencyInNumber);
      // }

      console.log(
        "HDHDHDHDDH",
        customer?.total_outstanding_amount / frequencyInNumber,
        customer?.total_outstanding_amount,
        frequencyInNumber,
        findFrq,
        instalmentExt[items?.manual_plan_setting?.maximum_instalment_extention],
        frequencyDays[
          planned_frequency &&
            planned_frequency[0] &&
            planned_frequency[0].id &&
            planned_frequency[0].id
        ]
      );

      let minimumAmount =
        customer?.total_outstanding_amount / frequencyInNumber;
      let dataValue = !is_allow_auto_debit_plan
        ? instalmentExt[
            items?.manual_plan_setting?.maximum_instalment_extention
          ] -
          (frequencyInNumber - 1) *
            frequencyDays[
              planned_frequency &&
                planned_frequency[0] &&
                planned_frequency[0].id
            ]
        : instalmentExt[
            items?.manual_plan_setting?.maximum_instalment_extention
          ] -
          ((frequencyInNumber - 1) *
            frequencyDays[
              planned_frequency &&
                planned_frequency[0] &&
                planned_frequency[0].id
            ] +
            1);

      console.log("AMOUNT<<<", minimumAmount);

      setValue("planned_installation_amount", minimumAmount.toFixed(2));

      // setValue("planned_installation_amount", Math.floor(minimumAmount));
      setIsMinimum(
        _.get(layoutData, "customer.min_payment")
          ? _.get(layoutData, "customer.min_payment")
          : minimumAmount.toFixed(2)
      );

      if (
        items?.manual_plan_setting?.maximum_instalment_extention ===
        "NO_VALIDATION"
      ) {
        setIsDayDiff(5);
      } else if (+dataValue > 14) {
        setIsDayDiff(14);
      } else {
        setIsDayDiff(dataValue);
      }
    }
  }, [planned_frequency]);

  useEffect(() => {
    // if (planned_installation_amount > isMinimum) {
    let frequencyInNumber =
      customer?.total_outstanding_amount / planned_installation_amount;
    let floorValue = Math.floor(frequencyInNumber);
    // setValue("instalation_count", floorValue);

    let roundOfFq = Math.ceil(frequencyInNumber);
    // let floorValue = Math.floor(frequencyInNumber);
    let dataValue = !is_allow_auto_debit_plan
      ? instalmentExt[
          items &&
            items?.manual_plan_setting &&
            items?.manual_plan_setting?.maximum_instalment_extention
        ] -
        (items &&
        items?.manual_plan_setting &&
        items?.manual_plan_setting?.payment_plan_residual !==
          "KEEP_LAST_INSTALMENT"
          ? floorValue - 1
          : roundOfFq - 1) *
          frequencyDays[
            planned_frequency && planned_frequency[0] && planned_frequency[0].id
          ]
      : instalmentExt[
          items &&
            items?.manual_plan_setting &&
            items?.manual_plan_setting?.maximum_instalment_extention
        ] -
        ((installationCount - 1) *
          frequencyDays[
            planned_frequency && planned_frequency[0] && planned_frequency[0].id
          ] +
          1);

    if (
      items?.manual_plan_setting?.maximum_instalment_extention ===
      "NO_VALIDATION"
    ) {
      setIsDayDiff(5);
    } else if (+dataValue > 14) {
      setIsDayDiff(14);
    } else {
      setIsDayDiff(dataValue);
    }
    // }
  }, [
    planned_installation_amount,
    planned_frequency && planned_frequency[0] && planned_frequency[0].id,
  ]);

  const calculateTenure = () => {
    if (is_allow_auto_debit_plan) {
      let findFrq =
        instalmentExt[
          items?.manual_plan_setting?.maximum_instalment_extention
        ] /
        frequencyDays[
          planned_frequency &&
            planned_frequency[0] &&
            planned_frequency[0].id &&
            planned_frequency[0].id
        ];
      let frequencyInNumber = Math.ceil(findFrq);
      console.log(installationCount, frequencyInNumber, "INSTALLLLLLLLLL____");

      let findLowValue = Math.min(
        frequencyInNumber,
        _.get(layoutData, "customer.max_instalment_count", null)
      );

      if (
        items?.manual_plan_setting?.maximum_instalment_extention !==
          "NO_VALIDATION" &&
        _.get(layoutData, "customer.max_instalment_count", null) &&
        findLowValue
      ) {
        if (installationCount > findLowValue) {
          setError("instalation_count", {
            type: "custom",
            message: `* Maximum allowed installments is  ${findLowValue}`,
          });
        } else {
          clearErrors("instalation_count");
        }
      } else if (
        installationCount >
        (_.get(layoutData, "customer.max_instalment_count")
          ? _.get(layoutData, "customer.max_instalment_count")
          : frequencyInNumber)
      ) {
        setError("instalation_count", {
          type: "custom",
          message: `* Maximum allowed installments is  ${
            _.get(layoutData, "customer.max_instalment_count")
              ? _.get(layoutData, "customer.max_instalment_count")
              : frequencyInNumber
          }`,
        });
      } else {
        clearErrors("instalation_count");
      }
    }

    const totalAmount = parseFloat(customer?.total_outstanding_amount) || 0;
    const installmentAmount = !is_allow_auto_debit_plan
      ? parseFloat(planned_installation_amount) || 0
      : totalAmount / installationCount;
    const startDate = start_date ? new Date(start_date) : "";
    const frequencyId =
      frequency && frequency?.[0] && frequency?.[0]?.id && frequency?.[0]?.id
        ? frequency &&
          frequency?.[0] &&
          frequency?.[0]?.id &&
          frequency?.[0]?.id
        : "WEEKLY";
    const residualAmountOption =
      items?.manual_plan_setting?.payment_plan_residual;
    const quotient = installmentAmount / totalAmount;
    const findReminder = (installmentAmount - totalAmount) * quotient;

    let remainingAmount = totalAmount;
    let currentDate = new Date(startDate);
    let months = 0;
    let newInstallments = [];

    const numOfInstallments = totalAmount / installmentAmount;
    const residualPerc = numOfInstallments - parseInt(numOfInstallments);
    const reminder = installmentAmount * residualPerc;

    let countInc = 0;
    const installments = Array.from({
      length: parseInt(numOfInstallments),
    }).map((inst, idx) => {
      const { type, count } = freqMoment[frequencyId];
      const installmentDate = moment
        .tz(
          `${start_date ? start_date.getFullYear() : ""}-${
            start_date ? start_date.getMonth() + 1 : ""
          }-${start_date ? start_date.getDate() : ""}`,
          "YYYY-MM-DD",
          timeZone
        )
        .startOf("day")
        .add(countInc, type)
        .utc()
        .valueOf();

      countInc += count;

      return {
        promise_date: installmentDate,
        promise_amount: {
          value:
            installmentAmount +
            (residualAmountOption === "FIRST_INSTALMENT" && idx === 0
              ? reminder
              : 0) +
            (residualAmountOption === "LAST_INSTALMENT" &&
            idx === parseInt(numOfInstallments) - 1
              ? reminder
              : 0),
          currency: customer.currency,
        },
      };
    });

    if (reminder != 0 && residualAmountOption === "KEEP_LAST_INSTALMENT") {
      const { type, count } = freqMoment[frequencyId];
      const installmentDate = moment
        .tz(
          `${start_date.getFullYear()}-${
            start_date.getMonth() + 1
          }-${start_date.getDate()}`,
          "YYYY-MM-DD",
          timeZone
        )
        .startOf("day")
        .add(countInc, type)
        .utc()
        .valueOf();

      installments.push({
        promise_date: installmentDate,
        promise_amount: {
          value: reminder,
          currency: customer.currency,
        },
      });
    }
    if (installments.length > 0) {
      setInstallments(installments);
    }
  };

  const openModal = (confirmMessage, paymentPlanData) => {
    setIsOpen(true);
    setIsConfirmMessage({
      layoutData: confirmMessage,
      paymentPlanData: paymentPlanData,
    });
  };
  // PP Loop End

  useEffect(() => {
    if (isCardLoader) {
      setTimeout(() => {
        setIsCardLoader(false);
        setIsPaymentPlanList(true);
      }, 5000);
    }
  }, [isCardLoader]);

  const currentDefaultFormatDetails = useSelector(
    (e) => e.layoutReduceData?.organization?.amount_format
  );

  console.log("currentDefaultFormatDetails", currentDefaultFormatDetails);
  // API SEND START
  const dispatch = useDispatch();

  const paymentPlanCompute = useMutation((data) => paymentPlanComputeFn(data), {
    onError: (error, variables, context) => {
      if (error.response) {
        let { data } = error.response;
        let errorData = data.message;
        toast.error(errorData);
      }
    },
    onSuccess: (data, variables, context) => {
      console.log("SUCC_DATA", data);
      if (data && data.data && data.data.data) {
        let convertInstallment = _.get(data, "data.data.installments", []).map(
          (e) => {
            return {
              promise_date: e.date,
              promise_amount: {
                value: e.amount,
                currency: customer?.currency,
              },
            };
          }
        );
        setValue(
          "planned_installation_amount",
          _.get(data, "data.data.discounted_installment_amount", "")
        );
        setIsCardLoader(true);
        setIsComputedData({ ...data.data.data });
        setIsComputeInstallments([...convertInstallment]);
      }
    },
  });
  const paymentPlanneds = useMutation(
    (data) =>
      is_allow_auto_debit_plan
        ? createAutoDebitPaymentPlan(data)
        : paymentPlanned(data),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        if (data && data.data && data.data.redirect_url) {
          window.location.replace(data.data.redirect_url);
        }

        toast.success(data.data.message);
        setIsOpen(false);
        dispatch(refetachLayoutData());
        setCount(count + 1);
        setValue("planned_installation_amount", "");
        reset();
      },
    }
  );

  // API SEND END

  let currencyGroupStyle =
    currentDefaultFormatDetails && currentDefaultFormatDetails == "lakhs"
      ? "lakh"
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_cs_dd"
      ? "thousand"
      : "thousand";

  let currencySeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? " "
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? "."
      : ",";

  let currencyDecimalSeparator =
    currentDefaultFormatDetails &&
    currentDefaultFormatDetails == "million_ss_cd"
      ? ","
      : currentDefaultFormatDetails &&
        currentDefaultFormatDetails == "million_ds_cd"
      ? ","
      : ".";

  let numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });

  const { default_color } = useSelector((s) => s.layoutReduceData);

  const [isSelected, setIsSelectd] = useState(false);

  useEffect(() => {
    if (planned_installation_amount || installationCount) {
      // Calculate installments based on planned_installation_amount and installationCount
      calculateTenure();
    }
  }, [planned_installation_amount, installationCount]);

  useEffect(() => {
    if (planned_installation_amount >= isMinimum) {
      calculateTenure();
    }
  }, [
    planned_installation_amount,
    planned_frequency,
    installationCount,
    start_date,
  ]);

  let findFreq = planned_frequency?.[0]?.id
    ? referenceData?.payment_plan_frequency.find(
        (e) => e.id === planned_frequency?.[0]?.id
      )
    : [];

  useEffect(() => {
    if (
      items?.manual_plan_setting?.maximum_instalment_extention !==
        "NO_VALIDATION" &&
      _.get(layoutData, "customer.min_payment")
    ) {
      let findFrq =
        instalmentExt[
          items?.manual_plan_setting?.maximum_instalment_extention
        ] /
        frequencyDays[
          planned_frequency &&
            planned_frequency[0] &&
            planned_frequency[0].id &&
            planned_frequency[0].id
        ];
      let frequencyInNumber = Math.ceil(findFrq);
      let minPayment = customer?.total_outstanding_amount / frequencyInNumber;
      let findMaxAmt = Math.max(
        _.get(layoutData, "customer.min_payment", null),
        minPayment
      ).toFixed(2);
      console.log("DATA_VALUE>>2");
      if (findMaxAmt > Number(planned_installation_amount)) {
        setError("planned_installation_amount", {
          type: "custom",
          message: `Minimum Installment amount is ${Number(findMaxAmt).toFixed(
            2
          )}`,
        });
      } else {
        clearErrors("planned_installation_amount");
      }
    } else if (
      Number(planned_installation_amount) <
      Number(
        _.get(layoutData, "customer.min_payment")
          ? _.get(layoutData, "customer.min_payment")
          : isMinimum
      )
    ) {
      console.log("DATA_VALUE>>");
      setError("planned_installation_amount", {
        type: "custom",
        message: `Minimum Installment amount is ${Number(
          _.get(layoutData, "customer.min_payment")
            ? _.get(layoutData, "customer.min_payment")
            : isMinimum
        ).toFixed(2)}`,
      });
    } else if (
      Number(customer?.total_outstanding_amount) <
      Number(planned_installation_amount)
    ) {
      console.log("DATA_VALUE>>1");
      setError("planned_installation_amount", {
        type: "custom",
        message: `Planned Payment Amount Should be Less than ${Number(
          customer?.total_outstanding_amount
        ).toFixed(2)}`,
      });
    } else {
      clearErrors("planned_installation_amount");
    }
  }, [planned_installation_amount, instalation_count]);

  // console.log(userChangedAmount, "kdwjhdwjkhd");
  useEffect(() => {
    if (userChangedAmount) {
      setValue(
        "planned_installation_amount",
        _.get(installments, "[0].promise_amount.value", "")
          ? _.get(installments, "[0].promise_amount.value", "").toFixed(2)
          : ""
      );
      // setUserChangedAmount(false);
    }
  }, [installments?.length]);
  const paymentPlanFrequency = _.get(
    items,
    "manual_plan_setting.payment_plan_freequency",
    []
  );

  let mapInstallment =
    isComputeinstallments && isComputeinstallments.length > 0
      ? isComputeinstallments
      : installments;

  const [isPaymentType, setIsPaymentType] = useState([{ id: "CARD" }]);

  return (
    <>
      <ConfirmationModel
        paymentPlanneds={paymentPlanneds}
        isOpen={isOpen}
        modelName={"PAYMENT_PLAN"}
        setIsOpen={setIsOpen}
        isConfirmMessage={isConfirmMessage}
        setIsConfirmMessage={setIsConfirmMessage}
      />
      {!isOpen && (
        <div className=" invoices-container invoices-header-container">
          {/* Body Start */}
          <div
            style={{
              background: "#fff",
              textAlign: "center",
              height: "calc(100vh - 145px)",
            }}
          >
            <div
              className="payment_title --mobl_title"
              style={{
                justifyContent: "center",
              }}
            >
              <>
                <div className="payment_back_btn">
                  <NavLink to={`/${hash}`}>
                    <div
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "50%",
                        border: "0.5px solid #cdced9",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => setIsSelect("chooseplan")}
                    >
                      <i className="mx-icon-Group-16969" />
                    </div>
                  </NavLink>
                </div>
              </>

              <div>
                <LabelLarge
                  $style={{
                    textAlign: "center",
                  }}
                  // className="org_name"
                  className="payment-list-org_name"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      // height: "27px",
                      gap: 9,
                      alignItems: "center",
                    }}
                  >
                    <LabelLarge>Build Your Own Payment Plan</LabelLarge>
                  </div>
                </LabelLarge>
                <ParagraphMedium $style={{ color: "#ADADAD" }}>
                  Planned Payment Amount:{" "}
                  {currencyList[customer?.currency]
                    ? currencyList[customer?.currency]
                    : customer?.currency}{" "}
                  <NumericFormat
                    displayType="text"
                    value={
                      customer?.total_outstanding_amount
                        ? customer?.total_outstanding_amount
                        : 0
                    }
                    thousandsGroupStyle={currencyGroupStyle}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={currencySeparator}
                    decimalSeparator={currencyDecimalSeparator}
                    renderText={(values) => <>{values}</>}
                  />
                </ParagraphMedium>
              </div>
            </div>

            <from onSubmit={handleSubmit()}>
              {isCardLoader ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "20px",
                      padding: "0px 25px",
                    }}
                  >
                    <div class="coupon-card">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          justifyContent: "center",
                          padding: "10px",
                        }}
                      >
                        <ParagraphMedium
                          $style={{
                            // textAlign: "left",
                            lineHeight: "18px",
                            fontWeight: "500",
                            marginRight: "5px",
                          }}
                        >
                          {_.get(
                            items,
                            "max_payment_plan_discount_percentage",
                            0
                          ) > 0
                            ? "Computing Discount"
                            : "Computing Schedule"}
                        </ParagraphMedium>
                        <div className="loader_dot"></div>
                      </div>
                      <div class="circle1"></div>
                      <div class="circle2"></div>
                    </div>
                  </div>
                </>
              ) : !isPaymentPlanList ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    height: "calc(100vh - 300px)",
                  }}
                >
                  {_.get(items, "max_payment_plan_discount_percentage", 0) >
                    0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "20px",
                        padding: "0px 25px",
                      }}
                    >
                      <div class="coupon-card">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <div>
                            <Offer />
                          </div>
                          <ParagraphMedium
                            $style={{
                              textAlign: "left",
                              lineHeight: "18px",
                              fontWeight: "500",
                            }}
                          >
                            Get upto{" "}
                            {_.get(
                              items,
                              "max_payment_plan_discount_percentage",
                              0
                            )}
                            % discount on your Payment amount with below
                            flexible Payment Options
                          </ParagraphMedium>
                        </div>
                        <div class="circle1"></div>
                        <div class="circle2"></div>
                      </div>
                    </div>
                  )}

                  <div className="planned_amount">
                    <div className="planned" style={{ textAlign: "start" }}>
                      <Controller
                        name="planned_frequency"
                        control={control}
                        rules={{ required: "Required" }}
                        render={({ field }) => (
                          <SelectBox
                            {...field}
                            name={field.name}
                            requiredAstric={true}
                            size={SIZE.mini}
                            readonly="readonly"
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            clearable={false}
                            label={intl.formatMessage({
                              id: "planned_frequency",
                            })}
                            placeholder={intl.formatMessage({
                              id: "planned_frequency",
                            })}
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            options={
                              referenceData &&
                              referenceData?.payment_plan_frequency &&
                              paymentPlanFrequency &&
                              paymentPlanFrequency.length > 0
                                ? referenceData?.payment_plan_frequency.filter(
                                    (e) =>
                                      items &&
                                      items.manual_plan_setting &&
                                      items?.manual_plan_setting?.payment_plan_freequency.includes(
                                        e.id
                                      )
                                  )
                                : referenceData?.payment_plan_frequency
                            }
                          />
                        )}
                      />
                    </div>
                  </div>

                  {is_allow_auto_debit_plan ? (
                    <>
                      <div className="planned_amount">
                        <div
                          className="planned"
                          style={{ display: "flex", gap: "10px" }}
                        >
                          <Controller
                            name="instalation_count"
                            control={control}
                            rules={{
                              required: "Required",
                            }}
                            render={({ field }) => (
                              <>
                                <TextBox
                                  {...field}
                                  type="Number"
                                  name={field.name}
                                  size={SIZE.mini}
                                  error={
                                    errors[field.name] &&
                                    errors[field.name].message
                                  }
                                  label={intl.formatMessage({
                                    id: "instalation_count",
                                  })}
                                  placeholder={intl.formatMessage({
                                    id: "instalation_count",
                                  })}
                                  value={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.target.value);
                                    setUserChangedAmount(true);
                                  }}
                                />
                              </>
                            )}
                          />

                          <ParagraphMedium $style={{ color: "#ADADAD" }}>
                            or
                          </ParagraphMedium>
                          <Controller
                            name="planned_installation_amount"
                            control={control}
                            rules={{
                              required: "Required",
                            }}
                            render={({ field }) => (
                              <>
                                <TextBox
                                  {...field}
                                  type="Number"
                                  name={field.name}
                                  size={SIZE.mini}
                                  error={
                                    errors[field.name] &&
                                    errors[field.name].message
                                  }
                                  label={intl.formatMessage({
                                    id: "planned_installation_amount",
                                  })}
                                  placeholder={intl.formatMessage({
                                    id: "planned_installation_amount",
                                  })}
                                  value={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.target.value);
                                  }}
                                  onBlur={(e) => {
                                    let countVl = Math.floor(
                                      customer?.total_outstanding_amount /
                                        planned_installation_amount
                                    );

                                    setValue(
                                      "instalation_count",
                                      countVl ? countVl : instalation_count
                                    );
                                  }}
                                />
                                {/* {errors &&
                          errors.promise_to_pay_amount                          
                          ?<span style={{ fontSize: "10px" }}>
                       
                      </span>:''} */}
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="planned_amount">
                      <div className="planned">
                        <Controller
                          name="planned_installation_amount"
                          control={control}
                          rules={{
                            required: "Required",
                          }}
                          render={({ field }) => (
                            <>
                              <TextBox
                                {...field}
                                type="Number"
                                name={field.name}
                                size={SIZE.mini}
                                error={
                                  errors[field.name] &&
                                  errors[field.name].message
                                }
                                label={intl.formatMessage({
                                  id: "planned_installation_amount",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "planned_installation_amount",
                                })}
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                }}
                              />
                              {/* {errors &&
                        errors.promise_to_pay_amount                          
                        ?<span style={{ fontSize: "10px" }}>
                     
                    </span>:''} */}
                            </>
                          )}
                        />
                      </div>
                    </div>
                  )}
                  <div className="planned_amount">
                    <div className="planned">
                      <Controller
                        name="planned_start_date"
                        control={control}
                        rules={{ required: "Required" }}
                        defaultValue={
                          (auto_debit_payment_gateways &&
                            auto_debit_payment_gateways[0] &&
                            auto_debit_payment_gateways[0].gateway_name ===
                              "ACCEPTBLUE") ||
                          auto_debit_payment_gateways?.[0]?.gateway_name ===
                            "REPAY_CHANNEL"
                            ? moment().add(1, "day").toDate()
                            : new Date()
                        }
                        render={({ field }) => (
                          <DateRangeSelect
                            {...field}
                            name={field.name}
                            error={
                              errors[field.name] && errors[field.name].message
                            }
                            requiredAstric={true}
                            size={SIZE.mini}
                            label={intl.formatMessage({
                              id: "planned_start_date",
                            })}
                            value={field.value}
                            onChange={(e) => field.onChange(e.date)}
                            minDate={
                              (auto_debit_payment_gateways &&
                                auto_debit_payment_gateways[0] &&
                                auto_debit_payment_gateways[0].gateway_name ===
                                  "ACCEPTBLUE") ||
                              auto_debit_payment_gateways?.[0]?.gateway_name ===
                                "REPAY_CHANNEL"
                                ? moment().add(1, "day").toDate()
                                : new Date()
                            }
                            maxDate={moment()
                              .add(isDayDiff - 1, "days")
                              .valueOf()}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="planned_amount">
                    <div style={{ width: "160px", marginBottom: "12px" }}>
                      <TextButton
                        kind={KIND.secondary}
                        disabled={
                          errors.planned_installation_amount ||
                          errors.instalation_count
                            ? true
                            : false
                        }
                        size="mini"
                        fullWidth
                        onClick={() => {
                          if (
                            _.get(
                              items,
                              "max_payment_plan_discount_percentage",
                              0
                            ) > 0
                          ) {
                            let value = {
                              instalation_amount: +planned_installation_amount,
                              frequency: frequency[0].id,
                              start_date: moment
                                .tz(
                                  `${start_date.getFullYear()}-${
                                    start_date.getMonth() + 1
                                  }-${start_date.getDate()}`,
                                  "YYYY-MM-DD",
                                  timeZone
                                )
                                .startOf("day")
                                .utc()
                                .valueOf(),
                              installments_count: +installationCount,
                            };
                            paymentPlanCompute.mutateAsync(value);
                          } else {
                            calculateTenure();
                            setIsCardLoader(true);
                          }
                        }}
                      >
                        Compute
                      </TextButton>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "calc(100vh - 220px)",
                  }}
                >
                  {isComputedData && isComputedData.discount_percentage > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "20px",
                        padding: "0px 25px",
                      }}
                    >
                      <div class="coupon-card" style={{ padding: "0" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            padding: "10px",
                          }}
                        >
                          <div>
                            <Offer />
                          </div>
                          <ParagraphMedium
                            $style={{
                              textAlign: "left",
                              lineHeight: "18px",
                              fontWeight: "500",
                            }}
                          >
                            You’ve got{" "}
                            <span
                              style={{ fontSize: "14px", fontWeight: "500" }}
                            >
                              {isComputedData &&
                                isComputedData.discount_percentage}
                              %
                            </span>{" "}
                            discount on your Payment amount{" "}
                            <span
                              style={{
                                textDecoration: "line-through",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              {currencyList[customer?.currency]
                                ? currencyList[customer?.currency]
                                : customer?.currency}
                              {isComputedData && (
                                <NumericFormat
                                  displayType="text"
                                  value={Number(isComputedData?.amount)}
                                  thousandsGroupStyle={currencyGroupStyle}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={currencySeparator}
                                  decimalSeparator={currencyDecimalSeparator}
                                  renderText={(values) => <>{values}</>}
                                />
                              )}{" "}
                            </span>{" "}
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#0FB158",
                                fontWeight: "400",
                                marginLeft: "3px",
                              }}
                            >
                              {currencyList[customer?.currency]
                                ? currencyList[customer?.currency]
                                : customer?.currency}
                              {isComputedData && (
                                <NumericFormat
                                  displayType="text"
                                  value={Number(
                                    isComputedData?.discounted_amount
                                  )}
                                  thousandsGroupStyle={currencyGroupStyle}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  thousandSeparator={currencySeparator}
                                  decimalSeparator={currencyDecimalSeparator}
                                  renderText={(values) => <>{values}</>}
                                />
                              )}
                            </span>
                            <span
                              style={{
                                color: "#0FB158",
                                backgroundColor: "#E8FFF2",
                                marginLeft: "5px",
                                padding: "3px 5px",
                                borderRadius: "5px",
                              }}
                            >
                              You save{" "}
                              <NumericFormat
                                displayType="text"
                                value={
                                  Number(isComputedData?.amount) -
                                  Number(isComputedData?.discounted_amount)
                                }
                                thousandsGroupStyle={currencyGroupStyle}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={currencySeparator}
                                decimalSeparator={currencyDecimalSeparator}
                                renderText={(values) => <>{values}</>}
                              />
                            </span>
                          </ParagraphMedium>
                        </div>
                        {isComputedData && isComputedData.is_max_discount ? (
                          <div
                            style={{
                              backgroundColor: "#EEF0F2",
                              borderTop: "1px solid #cdced9",
                              marginTop: "5px",
                              borderRadius: "0px 0px 10px 10px",
                              borderStyle: "dashed",
                              borderLeft: "0px",
                              borderBottom: "0px",
                              borderRight: "0px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "5px",
                            }}
                          >
                            <TipSvg />
                            <ParagraphMedium $style={{ color: "#787878" }}>
                              Tip: Get more discount by paying in short duration
                            </ParagraphMedium>
                          </div>
                        ) : (
                          <></>
                        )}

                        <div class="circle1"></div>
                        <div class="circle2"></div>
                      </div>
                    </div>
                  )}
                  <div className="pplist_date">
                    {/* {installments.length > 0 ? ( */}
                    <div className="planned_amount">
                      <div
                        className="--paymentplanrecipt"
                        style={{ position: "relative", overflow: "scroll" }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            borderTop: "1px solid #cdced9",
                            borderRight: "1px solid #cdced9",
                            borderLeft: "1px solid #cdced9",
                            borderRadius: "5px 5px 0px 0px",
                            background: "#FCFCFC",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            {isComputedData.discount_percentage > 0 ? (
                              <ParagraphMedium
                                $style={{ fontSize: "13px", fontWeight: "500" }}
                              >
                                Discounted Payment Plan
                              </ParagraphMedium>
                            ) : (
                              ""
                            )}

                            <ParagraphMedium $style={{ lineHeight: "18px" }}>
                              <span style={{ fontWeight: "500" }}>
                                {findFreq && findFreq.label}
                              </span>{" "}
                              Payment plan with installment amount of{" "}
                              {isComputedData &&
                              isComputedData.discount_percentage > 0 &&
                              isComputedData?.installment_amount ? (
                                <>
                                  <span
                                    style={{
                                      fontWeight: "500",
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    {currencyList[customer?.currency]
                                      ? currencyList[customer?.currency]
                                      : customer?.currency}
                                    {isComputedData?.installment_amount ? (
                                      <NumericFormat
                                        displayType="text"
                                        value={Number(
                                          isComputedData?.installment_amount
                                        )}
                                        thousandsGroupStyle={currencyGroupStyle}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={currencySeparator}
                                        decimalSeparator={
                                          currencyDecimalSeparator
                                        }
                                        renderText={(values) => <>{values}</>}
                                      />
                                    ) : (
                                      ""
                                    )}{" "}
                                  </span>{" "}
                                  <span style={{ color: "#0FB158" }}>
                                    {currencyList[customer?.currency]
                                      ? currencyList[customer?.currency]
                                      : customer?.currency}
                                    {isComputedData?.discounted_installment_amount ? (
                                      <NumericFormat
                                        displayType="text"
                                        value={Number(
                                          isComputedData?.discounted_installment_amount
                                        )}
                                        thousandsGroupStyle={currencyGroupStyle}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={currencySeparator}
                                        decimalSeparator={
                                          currencyDecimalSeparator
                                        }
                                        renderText={(values) => <>{values}</>}
                                      />
                                    ) : (
                                      ""
                                    )}{" "}
                                  </span>
                                </>
                              ) : (
                                <span style={{ fontWeight: "500" }}>
                                  {currencyList[customer?.currency]
                                    ? currencyList[customer?.currency]
                                    : customer?.currency}
                                  {_.get(
                                    mapInstallment,
                                    "[0].promise_amount.value",
                                    ""
                                  ) ? (
                                    <NumericFormat
                                      displayType="text"
                                      value={_.get(
                                        mapInstallment,
                                        "[0].promise_amount.value",
                                        0
                                      )}
                                      thousandsGroupStyle={currencyGroupStyle}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      thousandSeparator={currencySeparator}
                                      decimalSeparator={
                                        currencyDecimalSeparator
                                      }
                                      renderText={(values) => <>{values}</>}
                                    />
                                  ) : (
                                    " "
                                  )}
                                </span>
                              )}{" "}
                              starting from&nbsp;
                              <span style={{ fontWeight: "500" }}>
                                {start_date !== undefined
                                  ? moment(
                                      mapInstallment &&
                                        mapInstallment[0] &&
                                        mapInstallment[0].promise_date
                                    ).format(organization.date_format)
                                  : ""}{" "}
                              </span>
                              till{" "}
                              <span style={{ fontWeight: "500" }}>
                                {start_date !== undefined
                                  ? moment(
                                      mapInstallment &&
                                        mapInstallment[
                                          mapInstallment.length - 1
                                        ] &&
                                        mapInstallment[
                                          mapInstallment.length - 1
                                        ].promise_date
                                    ).format(organization.date_format)
                                  : ""}
                              </span>
                            </ParagraphMedium>
                          </div>
                          <IconButton
                            kind={KIND.tertiary}
                            size="mini"
                            onClick={() => setIsPaymentPlanList(false)}
                          >
                            <EditSvg size="18" color="#ADADAD" />
                          </IconButton>
                        </div>
                        <div className="planned_amount-table --modifi_table">
                          <div className="tableFixHead --table_width">
                            <table>
                              <thead>
                                <tr>
                                  <th>Instalments#</th>
                                  <th>Date</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody className="paymentplan_tablebody">
                                {mapInstallment.map((ele, i) => {
                                  return (
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          color: "#08083A",
                                        }}
                                      >
                                        {i + 1}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          color: "#08083A",
                                        }}
                                      >
                                        {moment
                                          .tz(ele?.promise_date, timeZone)
                                          .format(organization.date_format)}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                          color: "#08083A",
                                        }}
                                      >
                                        {
                                          <NumericFormat
                                            displayType="text"
                                            value={
                                              ele &&
                                              ele.promise_amount &&
                                              ele.promise_amount.value
                                            }
                                            thousandsGroupStyle={
                                              currencyGroupStyle
                                            }
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={
                                              currencySeparator
                                            }
                                            decimalSeparator={
                                              currencyDecimalSeparator
                                            }
                                            renderText={(values) => (
                                              <ParagraphMedium
                                                style={{ color: "#08083A" }}
                                              >
                                                {currencyList[
                                                  customer?.currency
                                                ]
                                                  ? currencyList[
                                                      customer?.currency
                                                    ]
                                                  : customer?.currency}
                                                {values}
                                              </ParagraphMedium>
                                            )}
                                          />
                                        }
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <div className="amount_monthly">
                        {isSelected
                          ? installments.map((ele, i) => {
                            return (
                              <>
                                <div className="monthly_label">
                                  <span style={{ fontWeight: "500" }}>
                                    {i + 1}.
                                  </span>
                                  <span style={{ fontWeight: "500" }}>
                                    {currencyList[customer?.currency]
                                      ? currencyList[customer?.currency]
                                      : customer?.currency}
                                    &nbsp;
                                    {Number(
                                      ele && ele.promise_amount
                                        ? ele &&
                                        ele.promise_amount &&
                                        ele.promise_amount.value
                                        : ""
                                    ).toFixed(2)}{" "}
                                  </span>
                                  on {moment(ele?.promise_date).format("dddd")}
                                  &nbsp;
                                  <span style={{ fontWeight: "500" }}>
                                    {moment(ele?.promise_date).format(
                                      organization.date_format
                                    )}
                                  </span>
                                </div>
                              </>
                            );
                          })
                          : ""}
                        <div
                          className={isSelected ? "isuparrow" : ""}
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            position: "absolute",
                            right: -3,
                            bottom: -4,
                          }}
                        >
                          <IconButton
                            kind={KIND.tertiary}
                            size={SIZE.mini}
                            onClick={() => setIsSelectd(!isSelected)}
                          >
                            <DownArrow />
                          </IconButton>
                        </div>
                      </div> */}
                      </div>
                    </div>
                    {/* {is_allow_auto_debit_plan ? (
                      <div className="planned_amount">
                        <div
                          className="planned"
                          style={{
                            display: "flex",
                            gap: "10px",
                            marginTop: "10px",
                            textAlign: "left",
                          }}
                        >
                          <div style={{ paddingTop: "3px" }}>
                            <Info />
                          </div>
                          <ParagraphMedium
                            $style={{ color: "#ADADAD", lineHeight: "16px" }}
                          >
                            Simplify your payment experience with auto-debit.
                            Securely provide your payment information to ensure
                            seamless and automated installment processing. A
                            processing fee, if applicable, will be shown on the
                            next page.
                          </ParagraphMedium>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )} */}
                    {/* ) : (
                ""
              )} */}
                    <div
                      className="mobile_manual_pay-btn"
                      style={{ paddingTop: "10px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "5px",
                          // flexDirection: "column",
                        }}
                      >
                        {items && items?.is_ach_enabled && (
                          <div
                            style={{ width: "150px", marginBottom: "10px" }}
                            className="payment_type"
                          >
                            <SelectBox
                              size="compact"
                              value={isPaymentType}
                              backspaceRemoves={false}
                              searchable={false}
                              clearable={false}
                              options={referenceData["online_payment_type"]}
                              onChange={(params) =>
                                setIsPaymentType(params.value)
                              }
                            />
                          </div>
                        )}
                        <div style={{ width: "150px" }}>
                          <TextButton
                            kind={KIND.primary}
                            fullWidth
                            size={SIZE.compact}
                            // disabled={
                            //   errors.planned_installation_amount ||
                            //     errors.instalation_count
                            //     ? true
                            //     : false
                            // }
                            type="button"
                            onClick={async () => {
                              let value = {
                                ...(items && items?.is_ach_enabled
                                  ? {
                                      payment_type:
                                        isPaymentType &&
                                        isPaymentType[0] &&
                                        isPaymentType[0].id,
                                    }
                                  : {}),
                                frequency: frequency[0].id,
                                ...(is_allow_auto_debit_plan
                                  ? {
                                      instalation_count: installationCount,
                                      is_predefined_plan: false,
                                    }
                                  : {
                                      instalation_amount: {
                                        currency: customer.currency,
                                        value: planned_installation_amount,
                                      },
                                      payment_plan_list:
                                        mapInstallment &&
                                        mapInstallment?.map((e) => {
                                          return {
                                            promise_amount: {
                                              currency:
                                                e?.promise_amount?.currency,
                                              value:
                                                e?.promise_amount?.value?.toFixed(
                                                  2
                                                ),
                                            },
                                            promise_date: e.promise_date,
                                          };
                                        }),
                                      amount: {
                                        value:
                                          customer.total_outstanding_amount,
                                        currency: customer.currency,
                                      },
                                    }),

                                start_date: moment
                                  .tz(
                                    `${start_date.getFullYear()}-${
                                      start_date.getMonth() + 1
                                    }-${start_date.getDate()}`,
                                    "YYYY-MM-DD",
                                    timeZone
                                  )
                                  .startOf("day")
                                  .utc()
                                  .valueOf(),
                              };
                              if (
                                _.get(
                                  layoutData,
                                  "customer.isEnableCPConfirmation",
                                  false
                                ) &&
                                _.get(
                                  layoutData,
                                  "customer.cpConfirmationTypes",
                                  []
                                )?.includes("PP")
                              ) {
                                openModal(
                                  _.get(
                                    layoutData,
                                    "customer.cpConfirmationMessages",
                                    []
                                  ),
                                  value
                                );
                              } else {
                                await paymentPlanneds.mutateAsync(value);
                              }
                            }}
                          >
                            Submit
                          </TextButton>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="tab_manual_pay"
                      style={{
                        background: `${default_color}`,
                        padding: "10px 10px",
                        // textAlign: "end",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        justifyContent: "end",
                        marginTop: "12px",
                        borderRadius: "5px",
                      }}
                    >
                      {items && items?.is_ach_enabled && (
                        <div
                          style={{ width: "120px" }}
                          className="payment_type"
                        >
                          <SelectBox
                            size="mini"
                            value={isPaymentType}
                            backspaceRemoves={false}
                            searchable={false}
                            clearable={false}
                            readonly="readonly"
                            options={referenceData["online_payment_type"]}
                            onChange={(params) =>
                              setIsPaymentType(params.value)
                            }
                          />
                        </div>
                      )}

                      <div
                        style={{
                          // padding: "15px",
                          // textAlign: "end",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "end",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <TextButton
                          kind={KIND.secondary}
                          size={SIZE.mini}
                          type="submit"
                          onClick={async () => {
                            let value = {
                              ...(items && items?.is_ach_enabled
                                ? {
                                    payment_type:
                                      isPaymentType &&
                                      isPaymentType[0] &&
                                      isPaymentType[0].id,
                                  }
                                : {}),
                              frequency: frequency[0].id,
                              ...(is_allow_auto_debit_plan
                                ? {
                                    instalation_count: installationCount,
                                    is_predefined_plan: false,
                                  }
                                : {
                                    instalation_amount: {
                                      currency: customer.currency,
                                      value: planned_installation_amount,
                                    },
                                    payment_plan_list:
                                      mapInstallment &&
                                      mapInstallment?.map((e) => {
                                        return {
                                          promise_amount: {
                                            currency:
                                              e?.promise_amount?.currency,
                                            value:
                                              e?.promise_amount?.value?.toFixed(
                                                2
                                              ),
                                          },
                                          promise_date: e.promise_date,
                                        };
                                      }),
                                    amount: {
                                      value:
                                        isComputedData &&
                                        isComputedData.discounted_amount
                                          ? isComputedData.discounted_amount
                                          : customer.total_outstanding_amount,
                                      currency: customer.currency,
                                    },
                                  }),

                              start_date: moment
                                .tz(
                                  `${start_date.getFullYear()}-${
                                    start_date.getMonth() + 1
                                  }-${start_date.getDate()}`,
                                  "YYYY-MM-DD",
                                  timeZone
                                )
                                .startOf("day")
                                .utc()
                                .valueOf(),
                            };

                            if (
                              _.get(
                                layoutData,
                                "customer.isEnableCPConfirmation",
                                false
                              ) &&
                              _.get(
                                layoutData,
                                "customer.cpConfirmationTypes",
                                []
                              )?.includes("PP")
                            ) {
                              openModal(
                                _.get(
                                  layoutData,
                                  "customer.cpConfirmationMessages",
                                  []
                                ),
                                value
                              );
                            } else {
                              await paymentPlanneds.mutateAsync(value);
                            }
                          }}
                          // disabled={
                          //   errors.planned_installation_amount ||
                          //     errors.instalation_count
                          //     ? true
                          //     : false
                          // }
                        >
                          Submit
                        </TextButton>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </from>
          </div>
        </div>
      )}
    </>
  );
};
export default PlannedAmount;
